import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import {
    auth,
    db,
    createUserWithEmailAndPassword,
    updateProfile,
    doc,
    setDoc,
    serverTimestamp,
    signInWithPopup,
    googleAuthProvider,
    getAdditionalUserInfo,
    getDoc,
    signOut,
    signInWithEmailAndPassword,
    updatePassword,
    updateDoc,
    storage,
    ref,
    uploadBytes,
    getDownloadURL,
    query as queryFirebase,
    where,
    collection,
    getDocs,
} from 'configs/firebase.config'
import { Timestamp } from 'firebase/firestore'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const handleSignOut = async () => {
        // console.log('USEAUTH - handleSignOut')
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        await signOut(auth)
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    
    // console.log('USEAUTH - NOFUNC')

    const signIn = async (values, reauthenticate = false) => {
        console.log('USEAUTH - SIGNIN')
        const { password, email } = values

        try {
            const signInWithEmailAndPasswordResult =
                await signInWithEmailAndPassword(auth, email, password)

            // console.log(
            //     'signInWithEmailAndPasswordResult',
            //     signInWithEmailAndPasswordResult
            // )

            const userRef = doc(
                db,
                'users',
                signInWithEmailAndPasswordResult.user.uid
            )

            const userResult = await getDoc(userRef)
            console.log('LOGGING user results')
            console.log(userResult)

            if (userResult.exists()) {
                const existingUserObject = userResult.data()
                console.log('Document data:', userResult.data())

                dispatch(setUser(userResult.data()))

                dispatch(onSignInSuccess(existingUserObject))

                const redirectUrl = query.get(REDIRECT_URL_KEY)

                if (!reauthenticate) {
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : appConfig.authenticatedEntryPath
                    )
                }
                let birthdayString = ''
                if (existingUserObject.birthday) {
                    // Handle different timestamp formats
                    if (existingUserObject.birthday instanceof Timestamp) {
                        birthdayString = existingUserObject.birthday.seconds;
                    } else if (existingUserObject.birthday.seconds) {
                        birthdayString = existingUserObject.birthday.seconds;
                    } else if (existingUserObject.birthday._seconds) {
                        birthdayString = existingUserObject.birthday._seconds;
                    }
                }

                return {
                    status: 'success',
                    message: `${existingUserObject.firstName},${existingUserObject.lastName},${birthdayString},${existingUserObject.id}`,
                }
            } else {
                return {
                    status: 'failed',
                    message: 'An error has occured here',
                }
            }
        } catch (error) {
            console.log('error', error)

            const errorCode = error.code
            const errorMessage = error.message
            if (
                errorCode === 'auth/wrong-password' ||
                errorCode === 'auth/missing-password'
            ) {
                return {
                    status: 'failed',
                    message: 'Incorrect credentials',
                }
            }
            if (errorCode === 'auth/user-not-found') {
                return {
                    status: 'failed',
                    message: 'No user corresponding to the given email',
                }
            }
            if (errorCode === 'auth/user-disabled') {
                return {
                    status: 'failed',
                    message: 'Account has been disabled',
                }
            }
            if (errorCode === 'auth/invalid-email') {
                return {
                    status: 'failed',
                    message: 'Email address is not valid',
                }
            } else {
                return {
                    status: 'failed',
                    message: errorMessage,
                }
            }
        }

        // try {
        // //   const resp = await apiSignIn(values);
        //   if (resp.data) {
        //     const { token } = resp.data;
        //     dispatch(onSignInSuccess(token));
        //     if (resp.data.user) {
        //       dispatch(
        //         setUser(
        //           resp.data.user || {
        //             avatar: "",
        //             userName: "Anonymous",
        //             authority: ["USER"],
        //             email: "",
        //           }
        //         )
        //       );
        //     }
        //     const redirectUrl = query.get(REDIRECT_URL_KEY);
        //     navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        //     return {
        //       status: "success",
        //       message: "",
        //     };
        //   }
        // } catch (errors) {
        //   return {
        //     status: "failed",
        //     message: errors?.response?.data?.message || errors.toString(),
        //   };
        // }
    }

    const signUp = async (values) => {
        console.log('USEAUTH - signUp')
        const { firstName, password, email, lastName } = values

        try {
            const createdUserResult = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            )

            console.log('createdUserResult', createdUserResult)

            const displayName = `${firstName} ${lastName}`

            // console.log('displayName', displayName)

            await updateProfile(createdUserResult.user, {
                displayName,
                // photoURL: imageUrl,
            })

            const response = await fetch(
                `${appConfig.serverApiPrefix}/create-stripe-account`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userEmail: email,
                        // customerId: createdUserResult.user.uid,
                        // fullName: displayName.trim()
                    }),
                }
            )

            const data = await response.json()

            // console.log('data', data)

            const userRef = doc(db, 'users', createdUserResult.user.uid)

            const newUserObject = {
                id: createdUserResult.user.uid,
                about: '',
                email,
                photoURL: '',
                isOnline: true,

                createdDate: serverTimestamp(),
                emailVerifiedAt: '',

                isEmailVerified: false,

                firstName,
                lastName,

                isPartner: false,
                companyName: '',
                hasCompletedAContent: false,

                friends: [],
                following: [],
                followers: [],

                authority: ['Student'],
                availableToHire: true,
                joinLearningCommunity: true,
                isBetaTester: true,
                birthday: "",
                state: "",
                gender: "",
                isDisabled: false,
                showEnrolledCourses: true,
                stripeCustomerId: data.stripeCustomerId,
                methodOfSignUp: 'Password',
            }

            // console.log('newUserObject', newUserObject)

            await setDoc(userRef, newUserObject)

            dispatch(setUser(newUserObject))

            dispatch(onSignInSuccess(newUserObject.id))

            const redirectUrl = query.get(REDIRECT_URL_KEY)

            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            )

            return {
                status: 'success',
                message: '',
            }
        } catch (error) {
            const errorCode = error.code
            const errorMessage = error.message

            if (errorCode === 'auth/weak-password') {
                return {
                    status: 'failed',
                    message: 'The password is too weak',
                }
            }
            if (errorCode === 'auth/email-already-in-use') {
                return {
                    status: 'failed',
                    message: 'Email already in use',
                }
            }
            if (errorCode === 'auth/invalid-email') {
                return {
                    status: 'failed',
                    message: 'Email address is not valid',
                }
            } else {
                return {
                    status: 'failed',
                    message: errorMessage,
                }
            }
        }
    }

    const updateUserPassword = async (values) => {
        const { newPassword } = values

        try {
            // console.log('auth', auth)
            const user = auth.currentUser
            // console.log('user', user)

            await updatePassword(user, newPassword)

            return {
                status: 'success',
                message: '',
            }
        } catch (error) {
            return {
                status: 'failed',
                message: 'An error has occured',
            }
        }
    }

    const updateUserProfile = async (values) => {
        // console.log('USEAUTH - updateUserProfile')
        const {
            firstName,
            lastName,
            photoURL,
            email,
            availableToHire,
            joinLearningCommunity,
            showEnrolledCourses,
            about,
            isAvatarAnObjectURL,
            userId,
            originalFirstName,
            originalLastName,

            birthday,
            state,
            gender,
        } = values

        // console.log('originalFirstName', originalFirstName)
        try {
            const displayName = `${firstName} ${lastName}`

            const user = auth.currentUser

            const userRef = doc(db, 'users', user.uid)

            if (!isAvatarAnObjectURL) {
                await Promise.all([
                    updateProfile(auth.currentUser, {
                        displayName,
                        photoURL,
                        email,
                    }),
                    updateDoc(userRef, {
                        firstName,
                        lastName,
                        photoURL,
                        email,
                        availableToHire,
                        joinLearningCommunity,
                        showEnrolledCourses,
                        about,
                            birthday,
                    state,
                    gender,
                    }),
                ])
            } else {
                const storageRef = ref(storage, `/user_photos/${user.uid}`)

                let blob = await fetch(photoURL).then((r) => r.blob())

                await uploadBytes(storageRef, blob)

                const uploadedPhotoUrl = await getDownloadURL(storageRef)

                // console.log('uploadedPhotoUrl', uploadedPhotoUrl)

                URL.revokeObjectURL(photoURL)

                await Promise.all([
                    updateProfile(auth.currentUser, {
                        displayName,
                        photoURL: uploadedPhotoUrl,
                        email,
                    }),
                    updateDoc(userRef, {
                        firstName,
                        lastName,
                        photoURL: uploadedPhotoUrl,
                        email,
                        availableToHire,
                        joinLearningCommunity,
                        showEnrolledCourses,
                        about,
                            birthday,
                    state,
                    gender,
                    }),
                ])
            }

            // await updateProfile(auth.currentUser, {
            //     displayName,
            //     photoURL,
            //     email,
            // })

            // await updateProfile(auth.currentUser, {
            //     displayName,
            //     photoURL,
            //     email,
            // })

            if (
                firstName !== originalFirstName ||
                lastName !== originalLastName
            ) {
                const coursesRef = collection(db, 'courses')

                const querySnapshot = await getDocs(
                    queryFirebase(coursesRef, where('createdBy', '==', userId))
                )
                querySnapshot.forEach((element) => {
                    const coursesDoc = element.data()

                    updateDoc(doc(db, 'courses', coursesDoc.id), {
                        createdByFirstName: firstName,
                        createdByLastName: lastName,
                    })
                })
            }

            return {
                status: 'success',
                message: '',
            }
        } catch (error) {
            console.log('error', error)
            return {
                status: 'failed',
                message: 'An error has occured',
            }
        }
    }

    const updateUserProfileViaAdminEdit = async (values) => {
        // console.log('USEAUTH - updateUserProfile')
        const {
            firstName,
            lastName,
            photoURL,
            email,
            availableToHire,
            joinLearningCommunity,
            showEnrolledCourses,
            about,
            isAvatarAnObjectURL,
            userId,
            originalFirstName,
            originalLastName,
        } = values

        // console.log('originalFirstName', originalFirstName)
        try {
            const displayName = `${firstName} ${lastName}`

            const user = auth.currentUser

            const userRef = doc(db, 'users', user.uid)

            if (!isAvatarAnObjectURL) {
                await Promise.all([
                    fetch(
                        `${appConfig.serverApiPrefix}/edit-user`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                displayName,
                                photoURL,
                                email,
                                userId
                            }),
                        }
                    ),
                    updateDoc(userRef, {
                        firstName,
                        lastName,
                        photoURL,
                        email,
                        availableToHire,
                        joinLearningCommunity,
                        showEnrolledCourses,
                        about,
                    }),
                ])
            } else {
                const storageRef = ref(storage, `/user_photos/${user.uid}`)

                let blob = await fetch(photoURL).then((r) => r.blob())

                await uploadBytes(storageRef, blob)

                const uploadedPhotoUrl = await getDownloadURL(storageRef)

                // console.log('uploadedPhotoUrl', uploadedPhotoUrl)

                URL.revokeObjectURL(photoURL)

                await Promise.all([
                    fetch(
                        `${appConfig.serverApiPrefix}/edit-user`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                displayName,
                                photoURL: uploadedPhotoUrl,
                                email,
                                userId
                            }),
                        }
                    ),
                    updateDoc(userRef, {
                        firstName,
                        lastName,
                        photoURL: uploadedPhotoUrl,
                        email,
                        availableToHire,
                        joinLearningCommunity,
                        showEnrolledCourses,
                        about,
                    }),
                ])
            }

            // await updateProfile(auth.currentUser, {
            //     displayName,
            //     photoURL,
            //     email,
            // })

            // await updateProfile(auth.currentUser, {
            //     displayName,
            //     photoURL,
            //     email,
            // })

            if (
                firstName !== originalFirstName ||
                lastName !== originalLastName
            ) {
                const coursesRef = collection(db, 'courses')

                const querySnapshot = await getDocs(
                    queryFirebase(coursesRef, where('createdBy', '==', userId))
                )
                querySnapshot.forEach((element) => {
                    const coursesDoc = element.data()

                    updateDoc(doc(db, 'courses', coursesDoc.id), {
                        createdByFirstName: firstName,
                        createdByLastName: lastName,
                    })
                })
            }

            return {
                status: 'success',
                message: '',
            }
        } catch (error) {
            console.log('error', error)
            return {
                status: 'failed',
                message: 'An error has occured',
            }
        }
    }

    const onGoogleSignIn = async (
        reauthenticate = false,
        comparisonObj = {}
    ) => {
        console.log('USEAUTH - onGoogleSignIn')
        try {
            const signInWithPopupResult = await signInWithPopup(
                auth,
                googleAuthProvider
            )

            const additionalInformation = getAdditionalUserInfo(
                signInWithPopupResult
            )
            //console.log('additional information ', additionalInformation)

            // const add = {
            //     isNewUser: true,
            //     providerId: 'google.com',
            //     profile: {
            //         name: 'Ian Ramos',
            //         granted_scopes:
            //             'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid',
            //         id: '104783357547219782545',
            //         verified_email: true,
            //         given_name: 'Ian',
            //         locale: 'en',
            //         family_name: 'Ramos',
            //         email: 'ianramosconsulting@gmail.com',
            //         picture:
            //             'https://lh3.googleusercontent.com/a/AGNmyxZjZhufMVkqvqN11_asx_mzzk1Pmz5hITbCeR4H=s96-c',
            //     },
            // }
            // console.log('additionalInformation', additionalInformation)

            if (additionalInformation.isNewUser) {
                // const displayName = `${additionalInformation.profile.given_name || ""} ${additionalInformation.profile.family_name || ""}`

                const response = await fetch(
                    `${appConfig.serverApiPrefix}/create-stripe-account`,
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            userEmail: signInWithPopupResult.user.email,
                            // customerId: signInWithPopupResult.user.uid,
                            // fullName: displayName.trim()
                        }),
                    }
                )

                const data = await response.json()

                const userRef = doc(db, 'users', signInWithPopupResult.user.uid)
                const userResult = await getDoc(userRef)
                console.log('User result ', userResult.data())
                let birthdayString = ''
                if (userResult.exists()) {
                    const existingUserObject = userResult.data()
                    if (existingUserObject.birthday) {
                        // Handle different timestamp formats
                        if (existingUserObject.birthday instanceof Timestamp) {
                            birthdayString = existingUserObject.birthday.seconds;
                        } else if (existingUserObject.birthday.seconds) {
                            birthdayString = existingUserObject.birthday.seconds;
                        } else if (existingUserObject.birthday._seconds) {
                            birthdayString = existingUserObject.birthday._seconds;
                        }
                    }
                }

                const newUserObject = {
                    id: signInWithPopupResult.user.uid,
                    about: '',
                    email: signInWithPopupResult.user.email,
                    photoURL: signInWithPopupResult.user.photoURL,
                    isOnline: true,

                    createdDate: serverTimestamp(),
                    emailVerifiedAt: additionalInformation.profile
                        .verified_email
                        ? serverTimestamp()
                        : null,

                    isEmailVerified:
                        additionalInformation.profile.verified_email,

                    firstName: additionalInformation.profile.given_name || '',
                    lastName: additionalInformation.profile.family_name || '',

                    isPartner: false,
                    companyName: '',
                    hasCompletedAContent: false,

                    friends: [],
                    following: [],
                    followers: [],

                    authority: ['Student'],
                    availableToHire: true,
                    joinLearningCommunity: true,
                    isBetaTester: true,
                    birthday: "",
                    state: "",
                    gender: "",
                    isDisabled: false,
                    showEnrolledCourses: true,

                    stripeCustomerId: data.stripeCustomerId,
                    methodOfSignUp: 'Google',
                }

                await setDoc(userRef, newUserObject)

                dispatch(onSignInSuccess(newUserObject.id))

                dispatch(setUser(newUserObject))

                const redirectUrl = query.get(REDIRECT_URL_KEY)

                if (!reauthenticate) {
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : appConfig.authenticatedEntryPath
                    )
                }
                console.log('useAuth google sign in case 1');
                // let birthdayString = '1000';
                //     if (existingUserObject.birthday) {
                //         // Handle different timestamp formats
                //         if (existingUserObject.birthday instanceof Timestamp) {
                //             birthdayString = existingUserObject.birthday.seconds;
                //         } else if (existingUserObject.birthday.seconds) {
                //             birthdayString = existingUserObject.birthday.seconds;
                //         } else if (existingUserObject.birthday._seconds) {
                //             birthdayString = existingUserObject.birthday._seconds;
                //         }
                //     }

                return {
                    status: 'success',
                    message: `${newUserObject.firstName},${newUserObject.lastName},${birthdayString},${newUserObject.id}`,
                }
            } else {
                const userRef = doc(db, 'users', signInWithPopupResult.user.uid)

                const userResult = await getDoc(userRef)
                console.log('USER RES - ', userResult)

                if (userResult.exists()) {
                    const existingUserObject = userResult.data()
                    console.log('existingUserObject:', userResult.data())

                    // console.log('comparisonObj', comparisonObj)

                    if (
                        reauthenticate &&
                        comparisonObj?.value !==
                            existingUserObject[comparisonObj?.field]
                    ) {
                        return {
                            status: 'failed',
                            message: 'Account used to verify did not match.',
                        }
                    }

                    dispatch(setUser(existingUserObject))

                    dispatch(onSignInSuccess(existingUserObject.id))

                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    console.log('redirect URL - ', redirectUrl)

                    if (!reauthenticate) {
                        navigate(
                            redirectUrl
                                ? redirectUrl
                                : appConfig.authenticatedEntryPath
                        )
                    }
                    let birthdayString = '1000';
                    if (existingUserObject.birthday) {
                        // Handle different timestamp formats
                        if (existingUserObject.birthday instanceof Timestamp) {
                            birthdayString = existingUserObject.birthday.seconds;
                        } else if (existingUserObject.birthday.seconds) {
                            birthdayString = existingUserObject.birthday.seconds;
                        } else if (existingUserObject.birthday._seconds) {
                            birthdayString = existingUserObject.birthday._seconds;
                        }
                    }
                    console.log('useAuth google sign in case 2')
                    return {
                        status: 'success',
                        message: `${existingUserObject.firstName},${existingUserObject.lastName},${birthdayString},${existingUserObject.id}`,
                    }
                } else {
                    return {
                        status: 'failed',
                        message: 'An error has occured',
                    }
                }
            }
        } catch (error) {
            const errorMessage = error.message || 'An error has occured'
            return {
                status: 'failed',
                message: errorMessage,
            }
        }
    }

    // const handleSignIn = async (user) => {
    //     console.log('JSON.stringify(loading)', JSON.stringify(loading))
    //     if (!loading) {
    // console.log('onAuthStateChanged ACTIVATED!!')
    //         const { uid } = user

    //         const userRef = doc(db, 'users', uid)
    //         const userResult = await getDoc(userRef)

    //         if (userResult.exists()) {
    //             const existingUserObject = userResult.data()
    //             console.log('Document data:', userResult.data())

    //             dispatch(setUser(userResult.data()))

    //             dispatch(onSignInSuccess(existingUserObject))
    //         } else {
    //             await handleSignOut()
    //         }
    //     }
    // }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        handleSignOut,
        // handleSignIn,
        onGoogleSignIn,
        updateUserPassword,
        updateUserProfile,
    }
}

export default useAuth
